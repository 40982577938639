import type { PaginationProps } from "antd";
import {
  Button,
  Col,
  Grid,
  Input,
  Pagination,
  Popover,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Typography,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { setSearchImageInfo } from "store/searchImageInfoSlice";
import styles from "./AnnotationPerDay.module.scss";
import { AnnotationPerDayLogic } from "./AnnotationPerDayLogic";

// Icons
import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { IoIosSearch } from "react-icons/io";

const { Title } = Typography;
const { useBreakpoint } = Grid;
const { Option } = Select;

function AnnotaionPerDay() {
  const dispatch = useDispatch();
  const { md } = useBreakpoint();
  const {
    datasets,
    searchTerm,
    setSearchTerm,
    datasetTableInfo,
    pageCount,
    setPageCount,
    pageLimit,
    setPageLimit,
    totalAnnotationCount,
    searched,
    setSearched,
  }: {
    userInfo: any;
    datasets: {
      loading: boolean;
      error: boolean;
      data: any[];
    };
    searchTerm: string;
    setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
    datasetTableInfo: any[];
    pageCount: number;
    setPageCount: (page: number) => void;
    pageLimit: number;
    setPageLimit: (limit: number) => void;
    totalAnnotationCount: number;
    searched: boolean;
    setSearched: (searched: boolean) => void;
  } = AnnotationPerDayLogic();

  const onShowSizeChange: PaginationProps["onShowSizeChange"] = (
    current,
    newPageLimit
  ) => {
    setPageLimit(newPageLimit);
  };
  const onChange: PaginationProps["onChange"] = (pageNumber) => {
    setPageCount(pageNumber);
  };

  const normalUserColumns: ColumnsType<any> = [
    {
      title: "Image",
      key: "imageName",
      width: "20%",
      render: (details: any) => {
        return (
          <>
            <Popover content={<>{details?.imageName}</>} placement="right">
              {/* {details?.imageName
                                ? details.imageName.substring(0, 18) + "..."
                                : "-"} */}
              {details?.identifier + "-" + details?.imgKey}
            </Popover>
          </>
        );
      },
    },
    {
      title: "Dataset Name",
      key: "nameAndDes",
      render: (details) => {
        return (
          <>
            {details.datasetName}
            <Popover
              content={
                details.datasetDescription
                  ? details.datasetDescription
                  : "No description"
              }
              placement="right"
              title="Description"
            >
              <InfoCircleOutlined
                className={styles.datasetsTableCol_infoIcon}
              />
            </Popover>
          </>
        );
      },
    },
    {
      title: "Camera Type",
      dataIndex: "cameraType_name",
      key: "cameraType_name",
    },
    {
      title: "Classifier",
      key: "classifier",
      render: (details) => {
        return <>{details.classifier ? details.classifier : "-"}</>;
      },
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      key: "updatedAt",
    },
    {
      title: "Your annotation",
      key: "yourAnnotation",
      render: (details) => {
        return (
          <>
            {details?.selectedClasses?.length > 0
              ? details.selectedClasses.map((cls:any) => cls.name).join(', ')
              : details?.selectedClass || '-'}
            {(details?.selectedClass || details?.selectedClasses) && (
              <Popover
                content={
                  <>
                    {details.selectedSubClasses.length > 0 ? (
                      details.selectedSubClasses.map((eachSubclass:any) => (
                        <li key={eachSubclass.id._id}>
                          {eachSubclass.id?.name} ({eachSubclass?.count})
                        </li>
                      ))
                    ) : (
                      <p>No subclasses found</p>
                    )}
                  </>
                }
                placement="right"
                title="More Details"
              >
                <InfoCircleOutlined className={styles.datasetsTableCol_infoIcon} />
              </Popover>
            )}
          </>
        );
      },
    },
    {
      title: "Actions",
      key: "action",
      render: (details) => {
        return (
          // <Space size="middle">
          //     <Link
          //         // to={`/buckets/${details._id}`}
          //         to={`/images/${details.datasetId}/${details.classifierId}/${details.bucketId}/0`}
          //     >
          //         {/* <Link to="/"> */}
          //         <Button
          //             className={styles.datasets_browseBtn}
          //             // onClick={() =>
          //             //     localStorage.removeItem("reviewerUsersInfo")
          //             // }
          //             // className={styles.table_visitBtn}
          //             // onClick={() =>
          //             //     dispatch(setSearchImageInfo(details.imgId))
          //             // }
          //         >
          //             Visit
          //         </Button>
          //     </Link>
          // </Space>

          <Space size="middle">
            <Link
              to={`/images/${details.datasetId}/${details.classifierId}/${details.bucketId}/0`}
            >
              <Button
                className={styles.datasets_browseBtn}
                onClick={() => dispatch(setSearchImageInfo(details.imgId))}
              >
                Visit
              </Button>
            </Link>
          </Space>
        );
      },
    },
  ];

  const [datasource, setDatasource] = useState(datasetTableInfo);
  useEffect(() => {
    const filteredData = datasetTableInfo.filter((entry) => {
      const key = entry.identifier + "-" + entry.imgKey;
      return key.includes(searchTerm);
    });
    setDatasource(filteredData);
  }, [searchTerm]);

  return (
    <>
      <Title level={2} className={styles.datasets_heading}>
        Today's Annotation History
      </Title>
      <Row className={styles.datasets_headingRow}>
        <Col xl={10} lg={24} sm={24} md={24} xs={24}>
          <div>
            <Input
              size="large"
              placeholder="Search by image..."
              prefix={<IoIosSearch className={styles.datasets_searchBoxIcon} />}
              className={styles.datasets_searchBox}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Button
              className={styles.datasets_searchBtn}
              onClick={() => {
                setSearched(!searched);
                setPageCount(1);
              }}
            >
              Search
            </Button>
          </div>
        </Col>
        <Col xl={14} lg={24} sm={24} md={24} xs={24}>
          {datasetTableInfo.length ? (
            <Pagination
              current={pageCount}
              total={totalAnnotationCount}
              pageSize={pageLimit}
              className={styles.datasets_pagination}
              showSizeChanger
              onShowSizeChange={onShowSizeChange}
              onChange={onChange}
            />
          ) : (
            ""
          )}
        </Col>
      </Row>

      <div>
        <h3 className={styles.totalAnnotations_by_date}>
          Total Annotations: {totalAnnotationCount}
        </h3>
      </div>

      <div className={styles.datasets_tableRow}>
        {datasets.error ? (
          <>
            <Title level={4} className={styles.datasets_heading}>
              Some error occured
            </Title>
          </>
        ) : datasets.loading ? (
          <div className={styles.loadingDiv}>
            <Spin
              size="large"
              indicator={
                <LoadingOutlined className={styles.loadingSpinIcon} spin />
              }
              tip="Fetching Annotations..."
              className={styles.loadingDiv_spin}
            />
          </div>
        ) : (
          <>
            <Table
              columns={normalUserColumns}
              dataSource={datasource.length ? datasource : datasetTableInfo}
              className={styles.table}
              pagination={false}
              scroll={{ x: 1000 }}
            />
          </>
        )}
      </div>
    </>
  );
}

export default AnnotaionPerDay;
