import type { RadioChangeEvent } from "antd";
import { getDatasetById } from "api/datasetApi";
import {
  createAnnotation,
  getAllBucketImages,
  getAllVirtualBucketImages,
  getClassesByClassifierId,
  getImageByPath,
  getReviewAnnotatorDetails,
} from "api/imagesDataApi";
import { updateAnnotatorImgStatusWhenReview } from "api/reviewBucketApi";
import * as markerjs2 from "markerjs2";
import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { userSelector } from "store/userSlice";
import { sortBucketsByKey } from "utils/functions";
import { colors } from "../../classifiers/ViewAll/Colors";

export const ViewImagesLogic = () => {
  const userInfo = useSelector(userSelector);
  const datasetInfo = useSelector(
    (state: any) => state.bucketsDatasetInfo.value
  );
  const searchedImageIdWhenSearch = useSelector(
    (state: any) => state.searchImageInfo.value
  );
  const reviewUserIds: any = localStorage.getItem("reviewerUsersInfo");
  const viewingSaveForLater: any = localStorage.getItem("viewingSaveForLater");
  const viewingNotAnnotated: any = localStorage.getItem("viewingNotAnnotated");
  const datasetIdWhenReview: any = localStorage.getItem("datasetIdWhenReview");
  const imgRef = useRef<HTMLImageElement | null>(null);
  const sourceImageRef = useRef<HTMLImageElement | null>(null);
  const location = useLocation();
  const isReviewRoute = location.pathname.includes("/images/review");
  const { datasetId, classifierId, bucketId, isDoubt } = useParams<string>();
  const [datasetName, setDatasetName] = useState<string>(datasetInfo.name);
  const [datasetIdentifier, setDatasetIdentifier] = useState<string>(
    datasetInfo.identifier
  );
  const [buckets, setBuckets] = useState<any[]>([]);
  const [selectedBucket, setSelectedBucket] = useState<string>("");
  const [selectedDropdownClassifier, setSelectedDropdownClassifier] =
    useState<any>();

  const [images, setImages] = useState<any[]>([]);
  const [emptyBucketImgHandler, setEmptyBucketImgHandler] =
    useState<boolean>(false);
  const [selectedImgCount, setSelectedImgCount] = useState<number>(0);
  const [imgNameAndDes, setImgNameAndDes] = useState<{
    name: string;
    filename: string;
  }>({
    name: "",
    filename: "",
  });
  const [selectedImage, setSelectedImage] = useState<any>({
    loading: false,
    error: false,
    data: "",
  });
  const [selectedImageErrMsg, setSelectedImageErrMsg] = useState<string>("");

  const [radioBucketNames, setRadioBucketNames] = useState([]);
  const [selectedRadioClass, setSelectedRadioClass] = useState<any>();
  const [selectedClasses, setSelectedClasses] = useState<string[]>([]);
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [selectedRadioSubClasses, setSelectedRadioSubClasses] = useState<any[]>(
    []
  );

  const [annotationMode, setAnnotationMode] = useState<boolean>(false);
  const [newRegionalAnnotatedImg, setNewRegionalAnnotatedImg] = useState<any>();
  const [handleNextProgress, setHandleNextProgress] = useState<boolean>(false);
  const [checkAnno, setCheckAnno] = useState<boolean>(false);
  const [checkAnnoSaveAndNext, setCheckAnnoSaveAndNext] = useState<any>("");

  const [userAnnotationId, setUserAnnotationId] = useState<string>("");
  const [reviewAnnotatorDetails, setReviewAnnotatorDetails] = useState<any[]>(
    []
  );
  const [userAnnotationRemarks, setUserAnnotationRemarks] =
    useState<string>("");
  const [saveForLater, setSaveForLater] = useState<boolean>(false);
  const [isDoubtToggler, setIsDoubtToggler] = useState<boolean>(
    isDoubt === "0" ? false : true
  );
  const [showClassesForReview, setShowClassesForReview] = useState<boolean>(
    isReviewRoute ? false : true
  );
  const [reviewAnnotationIsAccepted, setReviewAnnotationIsAccepted] =
    useState<boolean>(false);
  const [initialAnnoImgDetails, setInitialAnnoImgDetails] = useState<object[]>(
    []
  );

  // console.log(initialAnnoImgDetails);
  // New structure additions
  const [selectedBucketId, setSelectedBucketId] = useState<string>(bucketId!);
  const [bucketsNameAndCount, setBucketsNameAndCount] = useState<any[]>([]);
  const [createdAnnotationImgList, setCreatedAnnotationImgList] = useState<
    any[]
  >([]); // For handling images state when annotated (becomes empty when selectedBucket is changed)
  const [lastAnnotatedImgOfBucket, setLastAnnotatedImgOfBucket] = useState<
    object[]
  >([]);
  const [initialAnnoDetails, setInitialAnnoDetails] = useState<any[]>([]);
  const [savedForLaterBucketDetails, setSavedForLaterBucketDetails] =
    useState<any>({
      classId: "",
      name: "",
      imagesCount: 0,
    });
  const [notReviewedBucketDetails, setNotReviewedBucketDetails] = useState<any>(
    {
      classId: "",
      name: "",
      imagesCount: 0,
    }
  );
  const [allDrawnCoordinates, setAllDrawnCoordinates] = useState<any[]>([]);
  const [annotatedImagesForOneDay, setAnnotatedImagesForOneDay] = useState<
    any[]
  >([]); // for keeping track of annotatedImgs
  const [imgSelectedFromAnnotated, setImgSelectedFromAnnotated] = useState<{
    data: any;
    error: boolean;
    loading: boolean;
  }>({
    loading: false,
    error: false,
    data: {},
  });
  const [searchedImg, setSearchedImg] = useState<string>("");
  const [allUsersAnnotationEqual, setAllUsersAnnotationEqual] =
    useState<boolean>(false);

  const [selectedColorForSubClass, setSelectedColorForSubClass] =
    useState<string>("#fff");

  useEffect(() => {
    setSelectedImgCount(selectedImgCount + 1);
    if (selectedImgCount !== 0) {
      setSelectedImgCount(selectedImgCount - 1);
    }
  }, [handleNextProgress]);

  useEffect(() => {
    if (
      reviewAnnotatorDetails.length &&
      reviewAnnotatorDetails[0].annotation === null
    ) {
      setShowClassesForReview(true);
    } else {
      setShowClassesForReview(
        userInfo.permission_level === 2 || isReviewRoute ? false : true
      );
    }
  }, [reviewAnnotatorDetails]);

  useEffect(() => {
    if (reviewAnnotationIsAccepted) {
      isReviewRoute && agreeAnnotatorImgStatus();
      postAnnotation();
    }
  }, [images, reviewAnnotationIsAccepted]);

  useEffect(() => {
    setSelectedRadioClass(null);
    setSelectedRadioSubClasses([]);
    setNewRegionalAnnotatedImg(null);
    setUserAnnotationRemarks("");
    setSaveForLater(false);
    setEmptyBucketImgHandler(false);
    setImages([]);
    setSelectedImage({
      loading: false,
      error: false,
      data: "",
    });
    const getDatasetName = async () => {
      if ((!datasetName || !datasetIdentifier) && !isReviewRoute) {
        const res = await getDatasetById(datasetId);
        setDatasetName(res.name);
        setDatasetIdentifier(res.identifier);
      }
    };

    const getClassesAndSubClasses = async () => {
      const res = await getClassesByClassifierId(classifierId);
      const classifier = res?.classifier;


const formattedClassifier = [{
    ...classifier,
    category_id: classifier?.category_id?._id,  
}];

// Store the formatted classifier in localStorage
localStorage.setItem("classifierInformation", JSON.stringify(formattedClassifier));
      const sortedRadioBucketNames = res.classes?.sort(function (
        a: any,
        b: any
      ) {
        return parseFloat(a.key) - parseFloat(b.key);
      });
      setRadioBucketNames(sortedRadioBucketNames);
    };
    getDatasetName();
    // getImages();
    getClassesAndSubClasses();
  }, [datasetId, classifierId, bucketId, isDoubtToggler]);

  // Fetching images whenever bucket selection changes
  useEffect(() => {
    setSelectedImage({
      loading: true,
      error: false,
      data: "",
    });
    setImages([]);

    // Getting lastNotAnnotatedImg id
    let lastNotAnnoImg: {
      imgId: string;
      type: string;
    } = {
      imgId: "",
      type: "",
    };
    let imagesStateList: any[] = [];

    lastAnnotatedImgOfBucket.length > 0 &&
      lastAnnotatedImgOfBucket.forEach((eachBucket: any) => {
        if (eachBucket._id === selectedBucketId) {
          createdAnnotationImgList.length > 0 &&
            createdAnnotationImgList.forEach((eachImg: any) => {
              if (eachImg._id === eachBucket._id) {
                lastNotAnnoImg =
                  eachBucket.lastNotAnnotated === null
                    ? eachImg.annotated.length > 0
                      ? {
                          imgId:
                            eachImg.annotated[eachImg.annotated.length - 1]._id,
                          type: "stateFetched",
                        }
                      : {
                          imgId: "",
                          type: "",
                        }
                    : {
                        imgId: eachBucket.lastNotAnnotated,
                        type: "apiFetched",
                      };

                imagesStateList = eachImg.annotated;
              }
            });
        }
      });

    const fetchedImgInitialDetails: any = {};
    // Fetching lastNotAnnotatedImg details TODO: try to put it in function (repeatable code)
    const fetchImgDetail = async () => {
      try {
        if (lastNotAnnoImg.imgId) {
          const res = await getImageByPath(classifierId, lastNotAnnoImg.imgId);
          // Adding encodedImg & initialAnnotation
          fetchedImgInitialDetails.encodedImg = res.src;
          const user_annotation: any = [];
          if (res.user_annotation) {
            user_annotation.push(res.user_annotation);
            setUserAnnotationId(res.user_annotation._id);
            setUserAnnotationRemarks(res.user_annotation.remarks);
            setSaveForLater(res.user_annotation.is_doubt);
          }
          // Adding userAnnoId
          fetchedImgInitialDetails.userAnnotationId = res.user_annotation
            ? res.user_annotation._id
            : "";
          // Adding userRemarks
          fetchedImgInitialDetails.remarks = res.user_annotation
            ? res.user_annotation.remarks
            : "";

          // Adding imgName
          fetchedImgInitialDetails.imgName =
            res?.dataset_identifier + "-" + res?.image_key.toString();


          fetchedImgInitialDetails.filename = res.filename;

          setImgNameAndDes({
            name: res?.dataset_identifier + "-" + res?.image_key.toString(),
            filename: res.filename,
          });

          fetchedImgInitialDetails.initialAnnotation =
            res.user_annotation !== null ? user_annotation : [];

          res.user_annotation === null &&
            setInitialAnnoImgDetails(res.initial_annotation);

          setInitialAnnoDetails(fetchedImgInitialDetails.initialAnnotation);

          setSelectedImage({
            loading: false,
            error: false,
            data: fetchedImgInitialDetails,
          });
          // Adding selectedRadioBtns
          fetchedImgInitialDetails.initialAnnotation.length &&
            fetchedImgInitialDetails.initialAnnotation.map(
              (annotation: any) => {
                if (annotation.classifier === classifierId) {
                  setSelectedRadioClass(annotation.class);
                  setCheckAnnoSaveAndNext(annotation.class);
                  const filteredSubclasses: object[] =
                    annotation.subclasses.filter(
                      (value: any, index: number, self: any) =>
                        index === self.findIndex((t: any) => t.id === value.id)
                    );
                  setSelectedRadioSubClasses(filteredSubclasses);
                }
              }
            );
        }
      } catch (error: any) {
        toast.error(error.response?.data?.message);
      }
    };

    // Get all bucket images
    const getImages = async () => {
      try {
        const newRes = JSON.parse(viewingSaveForLater)
          ? await getAllVirtualBucketImages(
              datasetId,
              classifierId,
              selectedBucketId
            )
          : JSON.parse(viewingNotAnnotated)
          ? await getAllVirtualBucketImages(datasetId, classifierId, 0)
          : await getAllBucketImages(
              datasetId,
              classifierId,
              selectedBucketId,
              isReviewRoute
            );

        JSON.parse(viewingSaveForLater) &&
          setSavedForLaterBucketDetails({
            classId: newRes.class_id,
            name: newRes.class_name,
            imagesCount: newRes.images_count,
          });
        JSON.parse(viewingNotAnnotated) &&
          setNotReviewedBucketDetails({
            classId: newRes.class_id,
            name: newRes.class_name,
            imagesCount: newRes.images_count,
          });

        // Removing non-annotated bucket
        const filterFetchedClasses =
          newRes.allClasses.length > 0 &&
          newRes.allClasses.filter(
            (item: any) => item.name !== "Not Annotated"
          );
        const classesList = sortBucketsByKey(filterFetchedClasses);

        bucketsNameAndCount.length === 0 && setBucketsNameAndCount(classesList);

        const annotationImgList: any[] = [];
        const lastNotAnnotatedImgList: any[] = [];

        classesList.length &&
          classesList.forEach((eachClass: any) => {
            annotationImgList.push({
              name: eachClass.name,
              _id: eachClass._id,
              annotated: [],
            });
            lastNotAnnotatedImgList.push({
              name: eachClass.name,
              _id: eachClass._id,
              lastNotAnnotated: eachClass.last_not_annotated,
            });
          });

        setCreatedAnnotationImgList(annotationImgList);
        setLastAnnotatedImgOfBucket(lastNotAnnotatedImgList);

        let updatedImgArr = newRes.images.length
          ? newRes.images.map((eachImg: string) => {
              return {
                _id: eachImg,
              };
            })
          : [];

        setSelectedBucket(newRes.class_name);

        if (newRes.images.length || lastNotAnnoImg.imgId) {
          if (lastNotAnnoImg.type === "stateFetched") {
            if (imagesStateList.length > 0) {
              updatedImgArr = imagesStateList.concat(updatedImgArr);
              // Filtering duplicates
              updatedImgArr = updatedImgArr.filter(
                (value: any, index: any, self: any) =>
                  index === self.findIndex((t: any) => t._id === value._id)
              );
            }
          } else if (lastNotAnnoImg.type === "apiFetched") {
            if (imagesStateList.length > 0) {
              updatedImgArr = imagesStateList.concat(updatedImgArr);

              updatedImgArr = updatedImgArr.filter(
                (value: any, index: any, self: any) =>
                  index === self.findIndex((t: any) => t._id === value._id)
              );

              updatedImgArr.splice(0, imagesStateList.length);
              updatedImgArr = updatedImgArr.concat(imagesStateList);
            }
          }

          // Starting with Last annotated img
          const filterImgId: string = imgSelectedFromAnnotated.data._id
            ? imgSelectedFromAnnotated.data?._id
            : searchedImageIdWhenSearch // comes frm localStorage (since it is passed through another pg)
            ? searchedImageIdWhenSearch
            : lastNotAnnoImg.imgId; // when searched for an img, take searchedImageIdWhenSearch

          const indexOfLastAnnoImg: number = searchedImg
            ? updatedImgArr.findIndex((item: any) => item._id === searchedImg)
            : filterImgId
            ? updatedImgArr.findIndex((item: any) => item._id === filterImgId)
            : 0;

          searchedImg &&
            setImgNameAndDes({
              name: updatedImgArr[indexOfLastAnnoImg].imgName,
              filename: updatedImgArr[indexOfLastAnnoImg].filename,
            });

          setImages(updatedImgArr);
          setSelectedImgCount(indexOfLastAnnoImg);
          setSelectedImage({
            loading: false,
            error: false,
            data: updatedImgArr.length && updatedImgArr[indexOfLastAnnoImg],
          });
          setImgSelectedFromAnnotated({
            loading: false,
            error: false,
            data: {},
          });
          setSearchedImg("");
        } else {
          setEmptyBucketImgHandler(true);
        }
      } catch (error: any) {
        toast.error(error.response?.data?.message);
      }
    };
    fetchImgDetail();
    getImages();
  }, [selectedBucket]);

  useEffect(() => {
    setSelectedRadioClass(null);
    setSelectedRadioSubClasses([]);
    setUserAnnotationRemarks("");
    setSaveForLater(false);
    setInitialAnnoDetails([]);
    setAllDrawnCoordinates([]);
    setAllUsersAnnotationEqual(false);

    if (
      images.length > 0 &&
      (images[selectedImgCount]?.encodedImg === undefined ||
        !images[selectedImgCount]?.encodedImg)
    ) {
      setNewRegionalAnnotatedImg(null);
      setSelectedImage({
        loading: true,
        error: false,
        data: {},
      });
      setSelectedImageErrMsg("");

      const getBase64 = async () => {
        try {
          const res = await getImageByPath(
            classifierId,
            images[selectedImgCount]._id
          );

          // Adding encodedImg & initialAnnotation
          images[selectedImgCount].encodedImg = res.src;
          const user_annotation: any = [];
          if (res.user_annotation) {
            user_annotation.push(res.user_annotation);
            setUserAnnotationId(res.user_annotation._id);
            setUserAnnotationRemarks(res.user_annotation.remarks);
            setSaveForLater(res.user_annotation.is_doubt);
          }
          // Adding userAnnoId
          images[selectedImgCount].userAnnotationId = res.user_annotation
            ? res.user_annotation._id
            : "";
          // Adding userRemarks
          images[selectedImgCount].remarks = res.user_annotation
            ? res.user_annotation.remarks
            : "";

          // Adding imgName
          images[selectedImgCount].imgName =
            res?.dataset_identifier + "-" + res?.image_key.toString();


          images[selectedImgCount].filename = res.filename;

          setImgNameAndDes({
            name: res?.dataset_identifier + "-" + res?.image_key.toString(),
            filename: res.filename,
          });

          images[selectedImgCount].initialAnnotation =
            res.user_annotation !== null ? user_annotation : [];

          setInitialAnnoDetails(images[selectedImgCount].initialAnnotation);

          res.user_annotation === null &&
            setInitialAnnoImgDetails(res.initial_annotation);

          setSelectedImage({
            loading: false,
            error: false,
            data: images[selectedImgCount],
          });
          // Add coordinates
          const coordinates: object[] = [];
          res.user_annotation !== null &&
            res.user_annotation?.regional_annotation &&
            res.user_annotation?.regional_annotation.markers.length > 0 &&
            res.user_annotation?.regional_annotation.markers.map(
              (eachMarker: any) => {
                coordinates.push({
                  x1: eachMarker.left.toFixed(3),
                  y1: eachMarker.top.toFixed(3),
                  x2: (eachMarker.left + eachMarker.width).toFixed(3),
                  y2: (eachMarker.top + eachMarker.height).toFixed(3),
                });
              }
            );
          images[selectedImgCount].coordinates = coordinates;
          setAllDrawnCoordinates(coordinates);

          // Adding selectedRadioBtns
          images[selectedImgCount].initialAnnotation.length &&
            images[selectedImgCount].initialAnnotation.map(
              (annotation: any) => {
                if (annotation.classifier === classifierId) {
                  setSelectedRadioClass(annotation.class);
                  setCheckAnnoSaveAndNext(annotation.class);
                  const filteredSubclasses: object[] =
                    annotation.subclasses.filter(
                      (value: any, index: number, self: any) =>
                        index === self.findIndex((t: any) => t.id === value.id)
                    );
                  setSelectedRadioSubClasses(filteredSubclasses);
                }
              }
            );
        } catch (error: any) {
          setSelectedImageErrMsg(error.response?.data?.message);
          setSelectedImage({
            loading: false,
            error: true,
            data: {},
          });
        }
      };

      const getSelectedAnnotatorDetails = async () => {
        try {
          if (
            reviewUserIds ||
            userInfo.permission_level === 2 ||
            (userInfo.permission_level >= 3 && isReviewRoute)
          ) {
            const body: any = {
              reviewBucketId: datasetId, // this is actually review_bucekt id (in case of review_buekt only)
              datasetId: isReviewRoute ? null : datasetId,
              isReviewRoute,
              classifierId,
              imageId: images[selectedImgCount]._id,
              userIds: JSON.parse(reviewUserIds)
                ? JSON.parse(reviewUserIds)
                : [],
            };

            const res =
              // JSON.parse(reviewUserIds).length > 0
              // ?
              await getReviewAnnotatorDetails(body);
            // : "";

            images[selectedImgCount].allUsersAnnotations = res;
            setReviewAnnotatorDetails(res);

            const unique =
              res.length > 0
                ? [
                    ...new Set(
                      res.map(
                        (propYoureChecking: any) =>
                          propYoureChecking?.annotation?.class?._id
                      )
                    ),
                  ]
                : [];

            if (unique.length === 1 && unique[0] !== undefined) {
              setAllUsersAnnotationEqual(true);
            }
          }
        } catch (error: any) {
          toast.error(error.response?.data?.message);
        }
      };
      getBase64();
      getSelectedAnnotatorDetails();
    } else {
      setSelectedImage({
        loading: false,
        error: false,
        data: images[selectedImgCount],
      });

      images[selectedImgCount] &&
        setReviewAnnotatorDetails(
          images[selectedImgCount].allUsersAnnotations
            ? images[selectedImgCount].allUsersAnnotations
            : []
        );

      const unique =
        images[selectedImgCount]?.allUsersAnnotations &&
        images[selectedImgCount]?.allUsersAnnotations.length > 0
          ? [
              ...new Set(
                images[selectedImgCount].allUsersAnnotations.map(
                  (propYoureChecking: any) =>
                    propYoureChecking?.annotation?.class?._id
                )
              ),
            ]
          : [];

      if (unique.length === 1) {
        setAllUsersAnnotationEqual(true);
      }

      images[selectedImgCount] &&
        setUserAnnotationId(
          images[selectedImgCount].userAnnotationId
            ? images[selectedImgCount].userAnnotationId
            : ""
        );
      images[selectedImgCount] &&
        setUserAnnotationRemarks(
          images[selectedImgCount].remarks
            ? images[selectedImgCount].remarks
            : ""
        );
      images[selectedImgCount] &&
        setNewRegionalAnnotatedImg(
          images[selectedImgCount]?.initialAnnotation.length &&
            images[selectedImgCount].initialAnnotation[0]?.regional_annotation
            ? images[selectedImgCount].initialAnnotation[0].regional_annotation
            : null
        );

      images[selectedImgCount] &&
        setSaveForLater(
          images[selectedImgCount]?.initialAnnotation.length &&
            images[selectedImgCount].initialAnnotation[0]?.is_doubt
            ? images[selectedImgCount].initialAnnotation[0].is_doubt
            : false
        );

      images[selectedImgCount] &&
        setImgNameAndDes({
          name: images[selectedImgCount].imgName,
          filename: images[selectedImgCount].filename,
        });

      setAllDrawnCoordinates(images[selectedImgCount]?.coordinates);

      setInitialAnnoDetails(
        images[selectedImgCount]?.initialAnnotation.length &&
          images[selectedImgCount].initialAnnotation
      );

      images[selectedImgCount]?.initialAnnotation.length &&
        images[selectedImgCount].initialAnnotation.map((annotation: any) => {
          if (annotation.classifier === classifierId) {
            setSelectedRadioClass(annotation.class);
            setCheckAnnoSaveAndNext(annotation.class);
            // setSelectedRadioSubClasses(annotation.subclasses); // contains id & count
            annotation?.subclasses.map((subClass: any) => {
              setSelectedRadioSubClasses((prev) => [
                ...prev,
                {
                  id: subClass.id,
                  count: subClass.count,
                },
              ]);
            });
          }
        });
    }
  }, [images, selectedImgCount, checkAnno]);

  function goToSelectedImgFromAnnoImgs(jumpToSelectedFromAnnotatedImgs: any) {
    bucketsNameAndCount.length > 0 &&
      bucketsNameAndCount.forEach((eachBucket: any) => {
        if (
          eachBucket._id ===
          jumpToSelectedFromAnnotatedImgs.data.initialAnnotation[0]?.class
        ) {
          setSelectedBucket(eachBucket.name);
          setSelectedBucketId(
            jumpToSelectedFromAnnotatedImgs.data.initialAnnotation[0]?.class
          );
          setImgSelectedFromAnnotated(jumpToSelectedFromAnnotatedImgs);
        }
      });

    if (
      selectedBucketId ===
      jumpToSelectedFromAnnotatedImgs.data.initialAnnotation[0]?.class
    ) {
      const indexOfLastAnnoImg: number = jumpToSelectedFromAnnotatedImgs.data
        ?._id
        ? images.findIndex(
            (item: any) =>
              item._id === jumpToSelectedFromAnnotatedImgs.data?._id
          )
        : 0;

      setSelectedImgCount(indexOfLastAnnoImg);
      setSelectedImage({
        loading: false,
        error: false,
        data: images.length && images[indexOfLastAnnoImg],
      });
    } else {
      setSearchedImg(jumpToSelectedFromAnnotatedImgs.data?._id);
    }
  }

  const onChange = (e: RadioChangeEvent) => {
    setSelectedRadioSubClasses([]);
    setSelectedRadioClass(e.target.value);
  };

  const onCheckboxChange = (e: any) => {
    // remove id if it exists in array
    if (
      selectedRadioSubClasses.some(
        (radioSubClass: any) => radioSubClass.id === e
      )
    ) {
      const newArr = selectedRadioSubClasses.filter((item) => {
        return item.id !== e;
      });
      setSelectedRadioSubClasses(newArr);
    } else {
      setSelectedRadioSubClasses([
        ...selectedRadioSubClasses,
        {
          id: e,
          count: 1,
        },
      ]);
    }
  };

  // For updating the count value of selectedRadioSubClass
  function handleSelectedSubClassCount(
    radioSubClassId: string,
    updatedSubClassCount: number
  ) {
    const updatedList = selectedRadioSubClasses.map((subClass) => {
      if (subClass.id === radioSubClassId) {
        return { ...subClass, count: updatedSubClassCount };
      }
      return subClass;
    });
    setSelectedRadioSubClasses([]);
    setSelectedRadioSubClasses(updatedList);
  }

  function handleImages(bucket: any) {
    if (bucket.images.length) {
      setImages(bucket.images);
      setEmptyBucketImgHandler(false);
    } else {
      setImages([]);
      setEmptyBucketImgHandler(true);
    }
    setSelectedBucket(bucket.class.name);
  }

  function handleBucketImagesCount(bucket: any) {
    if (bucket.images_count > 0) {
      setEmptyBucketImgHandler(false);
    } else {
      setImages([]);
      setEmptyBucketImgHandler(true);
    }
    setSelectedBucket(bucket.name);
    setSelectedBucketId(bucket._id);
  }

  function handleAnnotationMode(mode: boolean) {
    if (mode === false) {
      const elements = document.getElementsByClassName("__markerjs2_");
      while (elements.length > 0) {
        elements[0].parentNode!.removeChild(elements[0]);
      }
    }
    // setAnnotationMode(mode);
    let regionalAnnotation: any;
    radioBucketNames.map((eachBucket: any) => {
      // if (eachBucket.name === selectedBucket) {
      selectedImage.data?.initialAnnotation.length > 0 &&
        selectedImage.data.initialAnnotation.map((eachAnnotation: any) => {
          if (eachBucket._id === eachAnnotation.class) {
            regionalAnnotation =
              eachAnnotation.regional_annotation &&
              eachAnnotation.regional_annotation;
            // : null;
          }
        });
      // }
    });

    // create a marker.js MarkerArea
    const markerArea = new markerjs2.MarkerArea(imgRef.current!);
    markerArea.settings.defaultColorSet = colors;
    markerArea.availableMarkerTypes = ["FrameMarker","EllipseFrameMarker"];
    // markerArea.renderMarkersOnly = true;
    if (mode) {
      if (imgRef.current) {
        // attach an event handler to assign annotated image back to our image element
        markerArea.addEventListener("render", (event: any) => {
          if (imgRef.current) {
            imgRef.current.src = event.dataUrl;
            setNewRegionalAnnotatedImg(event.state);
            setAnnotationMode(false);

            const coordinates: object[] = [];
            event.state.markers.length > 0 &&
              event.state.markers.map((eachMarker: any) => {
                coordinates.push({
                  x1: eachMarker.left.toFixed(3),
                  y1: eachMarker.top.toFixed(3),
                  x2: (eachMarker.left + eachMarker.width).toFixed(3),
                  y2: (eachMarker.top + eachMarker.height).toFixed(3),
                });
              });
            setAllDrawnCoordinates(coordinates);
          }
        });
        markerArea.show();
        const markerJsLink = document.querySelector(
          'a[href="https://markerjs.com/"]'
        );
        if (markerJsLink) {
          (markerJsLink as HTMLAnchorElement).style.display = "none";
        }
      }
      if (regionalAnnotation || newRegionalAnnotatedImg) {
        markerArea.restoreState(
          newRegionalAnnotatedImg ? newRegionalAnnotatedImg : regionalAnnotation
        );
      }
    }
    const markerToolbox = document.getElementsByClassName(
      "__markerjs2_toolbox"
    );
    if (markerToolbox.length > 0) {
      const ele = markerToolbox[0] as HTMLElement;
      ele.classList.add("markerjsColorSetHorizontalScoll");
    }
  }
  function handleAnnotationMarkerJs(
    annotatorImageRef: HTMLImageElement
  ) {
    let regionalAnnotation: any;
    radioBucketNames.forEach((eachBucket: any) => {
      if (selectedImage.data.initialAnnotation) {
        selectedImage.data.initialAnnotation.length > 0 &&
          selectedImage.data.initialAnnotation.forEach(
            (eachAnnotation: any) => {
              if (eachBucket._id === eachAnnotation.class) {
                regionalAnnotation =
                  eachAnnotation.regional_annotation &&
                  eachAnnotation.regional_annotation;
              }
            }
          );
      }
    });
    const markerArea = new markerjs2.MarkerArea(annotatorImageRef);
    markerArea.targetRoot = annotatorImageRef.parentElement as HTMLElement;
    markerArea.settings.defaultColor = selectedColorForSubClass || "#fff";
    markerArea.settings.defaultColorSet = ["#f0f0f0"];
    markerArea.availableMarkerTypes = ["FrameMarker","EllipseFrameMarker"];
    markerArea.renderMarkersOnly = true;
    markerArea.renderImageType = "image/png";
    markerArea.addEventListener("render", (event) => {
      if (annotatorImageRef) {
        setNewRegionalAnnotatedImg(event.state);
        annotatorImageRef.src = event.dataUrl;
        const coordinates: object[] = [];
        event.state.markers.length > 0 &&
          event.state.markers.map((eachMarker: any) => {
            coordinates.push({
              x1: eachMarker.left.toFixed(3),
              y1: eachMarker.top.toFixed(3),
              x2: (eachMarker.left + eachMarker.width).toFixed(3),
              y2: (eachMarker.top + eachMarker.height).toFixed(3),
            });
          });
        setAllDrawnCoordinates(coordinates);
      }
    });

    markerArea.show();

    if (regionalAnnotation || newRegionalAnnotatedImg) {
      markerArea.restoreState(
        newRegionalAnnotatedImg ? newRegionalAnnotatedImg : regionalAnnotation
      );
    }

    const markerToolbox = document.getElementsByClassName(
      "__markerjs2_toolbox"
    );
    if (markerToolbox.length > 0) {
      const ele = markerToolbox[0] as HTMLElement;
      ele.classList.add("markerjsColorSetHorizontalScoll");
    }
  }

  async function agreeAnnotatorImgStatus() {
    try {
      toast.success("Image reviewed successfully.");
    } catch (err: any) {
      toast.error(err.response?.data?.message);
    }
  }

  async function postAnnotation() {
    try {
      const createAnnoRes = await createAnnotation({
        imageId: selectedImage.data?._id
          ? selectedImage.data._id
          : images[selectedImgCount]._id,
        datasetId: isReviewRoute
          ? JSON.parse(datasetIdWhenReview)[0]
          : datasetId,
        classifierId,
        classId: selectedRadioClass ? selectedRadioClass : undefined,
        classesArray: selectedClasses && selectedClasses.length > 0 ? selectedClasses : undefined,
        subclasses: selectedRadioSubClasses,
        newRegionalAnnotatedImg: newRegionalAnnotatedImg && newRegionalAnnotatedImg,
        allDrawnCoordinates: allDrawnCoordinates && allDrawnCoordinates,
        userAnnotationRemarks: userAnnotationRemarks ? userAnnotationRemarks : "",
        annotationId: images[selectedImgCount].userAnnotationId
          ? images[selectedImgCount].userAnnotationId
          : userAnnotationId && userAnnotationId,
        saveForLater,
        review: isReviewRoute
          ? {
              status: reviewAnnotationIsAccepted ? "accepted" : "changed",
              reviewer: userInfo._id,
              date: Date.now(),
            }
          : null,
      });
  
      images[selectedImgCount].userAnnotationId = createAnnoRes._id
        ? createAnnoRes._id
        : userAnnotationId;
  
      images[selectedImgCount].remarks = userAnnotationRemarks;
      images[selectedImgCount].coordinates = allDrawnCoordinates;
  
      toast.success("Selection saved!");
    
      setSelectedClasses([]);
      setSelectedOption(null);
  
      // Remove img from images arr
      (!JSON.parse(viewingSaveForLater) || saveForLater === false) &&
        images.splice(
          images.findIndex((eachImage: any) => {
            return eachImage._id === selectedImage.data?._id;
          }),
          1
        );
  
      // Image removal from img arr if not same bucket selected or updates if same bucket is selected
      if (JSON.parse(viewingSaveForLater)) {
        if (saveForLater) {
          images.splice(
            images.findIndex((eachImage: any) => {
              return eachImage._id === selectedImage.data?._id;
            }),
            1
          );
          const updatedList = initialAnnoDetails?.map((annotationData: any) => {
            if (annotationData.classifier == classifierId) {
              annotationData.class = selectedRadioClass || undefined;
              annotationData.classesArray = selectedClasses || undefined;
              annotationData.subclasses = selectedRadioSubClasses;
              annotationData.regional_annotation = newRegionalAnnotatedImg;
              annotationData.classifier = classifierId;
              annotationData.is_doubt = saveForLater;
            }
            return annotationData;
          });
          selectedImage.data.initialAnnotation = updatedList;
          images.splice(selectedImgCount, 0, selectedImage.data);
          setImages(images);
          setSelectedImage({
            loading: false,
            error: false,
            data: images[selectedImgCount],
          });
          setCheckAnno(!checkAnno);
          setCheckAnnoSaveAndNext(null);
          setReviewAnnotationIsAccepted(false);
          setUserAnnotationRemarks(userAnnotationRemarks);
          setSaveForLater(saveForLater);
        } else {
          setImages(images);
          setSelectedImage({
            loading: false,
            error: false,
            data: images[selectedImgCount],
          });
          setCheckAnno(!checkAnno);
          setCheckAnnoSaveAndNext(null);
          setReviewAnnotationIsAccepted(false);
          setSavedForLaterBucketDetails({
            ...savedForLaterBucketDetails,
            imagesCount: images.length,
          });
  
          const newUpdatedBucketCount: any[] = [];
          bucketsNameAndCount.length &&
            bucketsNameAndCount.map((eachBucket) => {
              if (selectedClasses && selectedClasses.includes(eachBucket._id)) {
                newUpdatedBucketCount.push({
                  ...eachBucket,
                  images_count: eachBucket.images_count + 1,
                });
              } else if (eachBucket._id === selectedBucketId) {
                newUpdatedBucketCount.push({
                  ...eachBucket,
                  images_count: eachBucket.images_count - 1,
                });
              } else {
                newUpdatedBucketCount.push(eachBucket);
              }
            });
  
          setBucketsNameAndCount(newUpdatedBucketCount);
        }
      } else if (
        selectedClasses
          ? selectedClasses.includes(selectedBucketId)
          : selectedRadioClass === selectedBucketId
      ) {
        if (isDoubtToggler && !saveForLater) {
          // Do nothing
        } else {
          const updatedList = initialAnnoDetails?.map((annotationData: any) => {
            if (annotationData.classifier == classifierId) {
              annotationData.class = selectedRadioClass || undefined;
              annotationData.classesArray = selectedClasses || undefined;
              annotationData.subclasses = selectedRadioSubClasses;
              annotationData.regional_annotation = newRegionalAnnotatedImg;
              annotationData.classifier = classifierId;
              annotationData.is_doubt = saveForLater;
            }
            return annotationData;
          });
          selectedImage.data.initialAnnotation = updatedList;
          images.splice(selectedImgCount, 0, selectedImage.data);
          setImages(images);
          setSelectedImage({
            loading: false,
            error: false,
            data: images[selectedImgCount],
          });
          setCheckAnno(!checkAnno);
          setCheckAnnoSaveAndNext(null);
          setReviewAnnotationIsAccepted(false);
          setSaveForLater(saveForLater);
        }
      } else {
        setImages(images);
        setSelectedImage({
          loading: false,
          error: false,
          data: images[selectedImgCount],
        });
  
        setCheckAnno(!checkAnno);
        setCheckAnnoSaveAndNext(null);
        setReviewAnnotationIsAccepted(false);
  
        const newUpdatedBucketCount: any[] = [];
        bucketsNameAndCount.length &&
          bucketsNameAndCount.map((eachBucket) => {
            if (selectedClasses && selectedClasses.includes(eachBucket._id)) {
              newUpdatedBucketCount.push({
                ...eachBucket,
                images_count: eachBucket.images_count + 1,
              });
            } else if (eachBucket._id === selectedBucketId) {
              newUpdatedBucketCount.push({
                ...eachBucket,
                images_count: eachBucket.images_count - 1,
              });
            } else {
              newUpdatedBucketCount.push(eachBucket);
            }
          });
  
        setBucketsNameAndCount(newUpdatedBucketCount);
      }
  
      // Updating createdAnnotationImgList
      const updatedAnnotatedImgList: any[] = [];
      createdAnnotationImgList.length > 0 &&
        createdAnnotationImgList.map((eachClassDetails: any) => {
          if (
            selectedClasses
              ? selectedClasses.includes(eachClassDetails._id)
              : eachClassDetails._id === selectedRadioClass
          ) {
            const updatedList = initialAnnoDetails.length
              ? initialAnnoDetails?.map((annotationData: any) => {
                  if (annotationData.classifier == classifierId) {
                    annotationData.class = selectedRadioClass || undefined;
                    annotationData.classesArray = selectedClasses || undefined;
                    annotationData.subclasses = selectedRadioSubClasses;
                    annotationData.regional_annotation = newRegionalAnnotatedImg;
                    annotationData.classifier = classifierId;
                    annotationData.is_doubt = saveForLater;
                  }
                  return annotationData;
                })
              : [];
  
            const updatedAnno: any = {};
  
            updatedAnno.class = selectedRadioClass || undefined;
            updatedAnno.classesArray = selectedClasses || undefined;
            updatedAnno.subclasses = selectedRadioSubClasses;
            updatedAnno.regional_annotation = newRegionalAnnotatedImg;
            updatedAnno.classifier = classifierId;
            updatedAnno.is_doubt = saveForLater;
  
            selectedImage.data.initialAnnotation =
              updatedList.length > 0 ? updatedList : [updatedAnno];
  
            updatedAnnotatedImgList.push({
              name: eachClassDetails.name,
              _id: eachClassDetails._id,
              annotated: [...eachClassDetails.annotated, selectedImage.data],
              lastNotAnnotated: "",
            });
          } else {
            updatedAnnotatedImgList.push(eachClassDetails);
          }
        });
  
      updatedAnnotatedImgList.length > 0 &&
        setCreatedAnnotationImgList(updatedAnnotatedImgList);
  
      // For handling annotated images state (for drawer)
      const updatedAnnoImagesForOneDay = annotatedImagesForOneDay.filter(
        function (annotatedImg) {
          return annotatedImg.data._id != selectedImage.data._id;
        }
      );
      updatedAnnoImagesForOneDay.push(selectedImage);
      setAnnotatedImagesForOneDay(updatedAnnoImagesForOneDay);
  
      images.length === 0 && setEmptyBucketImgHandler(true);
    } catch (err: any) {
      toast.error("Oops! Couldn't save selection", err.response?.data?.message);
    }
  }

  return {
    datasetName,
    classifierId,
    buckets,
    selectedBucket,
    selectedDropdownClassifier,
    setSelectedDropdownClassifier,
    handleImages,
    images,
    selectedImage,
    selectedImageErrMsg,
    selectedImgCount,
    setSelectedImgCount,
    onChange,
    radioBucketNames,
    onCheckboxChange,
    annotationMode,
    setAnnotationMode,
    selectedRadioClass,
    selectedRadioSubClasses,
    setSelectedRadioClass,
    setSelectedRadioSubClasses,
    userAnnotationId,
    setUserAnnotationId,
    userAnnotationRemarks,
    setUserAnnotationRemarks,
    saveForLater,
    setSaveForLater,
    handleSelectedSubClassCount,
    handleAnnotationMode,
    postAnnotation,
    imgRef,
    emptyBucketImgHandler,
    checkAnnoSaveAndNext,
    reviewAnnotatorDetails,
    isDoubtToggler,
    setIsDoubtToggler,
    isReviewRoute,
    showClassesForReview,
    setShowClassesForReview,
    agreeAnnotatorImgStatus,
    reviewAnnotationIsAccepted,
    setReviewAnnotationIsAccepted,
    imgNameAndDes,
    bucketsNameAndCount,
    handleBucketImagesCount,
    viewingSaveForLater,
    savedForLaterBucketDetails,
    selectedBucketId,
    annotatedImagesForOneDay,
    goToSelectedImgFromAnnoImgs,
    allUsersAnnotationEqual,
    handleAnnotationMarkerJs,
    sourceImageRef,
    selectedColorForSubClass,
    setSelectedColorForSubClass,
    notReviewedBucketDetails,
    selectedClasses,
    setSelectedClasses,
    setSelectedOption,
    selectedOption,
    initialAnnoImgDetails,
    initialAnnoDetails,
  };
};
