import { getAllAccessibleDatasets } from "api/datasetApi";
import {
  getImageAnnotation,
  getSearchedImagesData
} from "api/imagesDataApi";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";

interface ImagesType {
  imgId: string;
  key: string;
  imageName: string;
  datasetId: string;
  classifierId: string;
  bucketId: string;
  imgSrc: string;
  selectedClass: string;
  selectedSubClasses: any[];
  classesArray:any[];
}

export const SearchImagesLogic = () => {
  const [datasets, setDatasets] = useState<{
    loading: boolean;
    error: boolean;
    fetched: boolean;
    data: any;
  }>({
    loading: false,
    error: false,
    fetched: false,
    data: {},
  });

  /** Fetch datasets */
  useEffect(() => {
    setDatasets({
      loading: true,
      error: false,
      fetched: true,
      data: [],
    });

    try {
      const fetchDatasets = async () => {
        const res = await getAllAccessibleDatasets({
          pageCount: 0,
          pageLimit: -1,
        });
        setDatasets({
          loading: false,
          error: false,
          fetched: true,
          data: res,
        });
      };
      fetchDatasets();
    } catch (error) {
      setDatasets({
        loading: false,
        error: true,
        fetched: true,
        data: [],
      });
    }
  }, []);
  let dataKeyCount = 0;
  let imagesDataTableInfo: ImagesType[] = [];
  const [imageKey, setImageKey] = useState<number>(0);
  const [classifiers, setClassifiers] = useState<any[]>([]);
  const [imageAnnotationClassId, setImageAnnotationClassId] =
    useState<string>("");
  const [isClassifierSelectorVisible, setIsClassifierSelectorVisible] =
    useState<boolean>(false);
  const [selectedDataset, setSelectedDataset] = useState<string>("");
  const [selectedClassifier, setSelectedClassifier] = useState<string>("");
  const [disableVisitInTable, setDisableVisitInTable] = useState(true);
  const [pageCount, setPageCount] = useState<number>(1);
  const [pageLimit, setPageLimit] = useState<number>(10);
  const [imagesData, setImagesData] = useState<{
    loading: boolean;
    error: boolean;
    data: any[];
  }>({
    loading: false,
    error: false,
    data: [],
  });
  const [imagesErrMsg, setImagesErrMsg] = useState<string>("");
  const [totalAnnotationCount, setTotalAnnotationCount] = useState<
    number | null
  >(null);

  /** Updating classifiers list when dataset selected/ updated */
  useEffect(() => {
    if (selectedDataset) {
      datasets.data?.datasets.filter((eachDataset: any) => {
        if (eachDataset._id === selectedDataset) {
          setClassifiers(eachDataset.classifiers);
        }
      });
    }
  }, [selectedDataset, datasets]);

  /** Images fetch func */
  function imagesDataFetcher() {
    try {
      setImagesData({
        loading: true,
        error: false,
        data: [],
      });

      const searchForImages = async () => {
        const res = await getSearchedImagesData({
          datasetId: selectedDataset,
          classifierId: selectedClassifier,
          imageKey,
          pageCount,
          pageLimit,
        });
        setImagesData({
          loading: false,
          error: false,
          data: res.paginateImage,
        });
        setTotalAnnotationCount(res.totalObjects);
        // when we have searched images and we have imageId, we need to show classifier selector
        setIsClassifierSelectorVisible(true);
      };
      searchForImages();
    } catch (error: any) {
      setImagesData({
        loading: false,
        error: true,
        data: [],
      });
      setImagesErrMsg(error.response.data?.message);
    }
  }

  function fetchImageAnnotation() {
    const image_id = imagesData?.data[0]?._id;
    try {
      (async () => {
        const res = await getImageAnnotation({
          image_id,
          classifier_id: selectedClassifier,
          dataset_id: selectedDataset,
        });
        if (res.class !== undefined) {
          setImageAnnotationClassId(res.class);
        }
        setDisableVisitInTable(false);
      })();
    } catch (error) {}
  }

  // Fetching again when count / limit changes
  useEffect(() => {
    if (selectedClassifier) {
      imagesDataFetcher();
    }
  }, [pageCount, pageLimit]);

  // when a classifier is selected
  useEffect(() => {
    if (selectedClassifier && imagesData.data.length) {
      fetchImageAnnotation();
    }
  }, [selectedClassifier, imageKey]);

  // on searchBtn click
  function fetchImagesData() {
    if (selectedDataset) {
      imagesDataFetcher();
    } else {
      toast.error("Please select dataset and classifier");
    }
  }
  function addImagesDataInTable() {
    imagesDataTableInfo = [];
    imagesData.data.length &&
      imagesData.data.forEach((eachImg: any) => {
        let annotatedClassId = "";
        let annotatedClass = "";
        let classesArray = null;

        if (eachImg.user_annotation) {
          // Handle case where user_annotation exists
          if (eachImg.user_annotation.class) {
            // Single class case
            annotatedClassId = eachImg.user_annotation.class._id;
            annotatedClass = eachImg.user_annotation.class.name;
          } else if (eachImg.user_annotation.classesArray?.length > 0) {
            // Multiple classes case
            annotatedClassId = eachImg.user_annotation.classesArray[0]._id;
            classesArray = eachImg.user_annotation?.classesArray;
          }
        } else if (eachImg.initial_annotation.length > 0) {
          // Handle initial annotation case
          eachImg.initial_annotation.forEach((eachInitialAnnotation: any) => {
            if (eachInitialAnnotation.classifier._id === selectedClassifier) {
              annotatedClassId = eachInitialAnnotation?.class?._id;
            }
          });
        }

        const annotatedSubClasses: any[] = [];
        if (eachImg.user_annotation?.subclasses?.length > 0) {
          eachImg.user_annotation.subclasses.forEach((eachSubClass: any) => {
            annotatedSubClasses.push({
              id: eachSubClass.id,
              name: eachSubClass.id.name,
              count: eachSubClass.count,
            });
          });
        }

        imagesDataTableInfo.push({
          imgId: eachImg._id,
          key: dataKeyCount.toString(),
          imageName:
            eachImg.dataset_id?.identifier + "-" + eachImg?.key.toString(),
          datasetId: selectedDataset,
          classifierId: selectedClassifier,
          bucketId: imageAnnotationClassId || annotatedClassId,
          imgSrc: eachImg?.thumbnail?.uri,
          selectedClass: annotatedClass,
          selectedSubClasses: annotatedSubClasses,
          classesArray: classesArray, // Add the classesArray to the output if it exists
        });
        dataKeyCount += 1;
      });
  }
  addImagesDataInTable();

  return {
    datasets,
    setDatasets,
    imageKey,
    setImageKey,
    selectedDataset,
    setSelectedDataset,
    classifiers,
    selectedClassifier,
    setSelectedClassifier,
    fetchImagesData,
    pageCount,
    setPageCount,
    pageLimit,
    setPageLimit,
    imagesData,
    imagesDataTableInfo,
    totalAnnotationCount,
    imagesErrMsg,
    disableVisitInTable,
    isClassifierSelectorVisible,
  };
};
